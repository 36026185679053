import React from 'react';
import { Link } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Container from '../components/Container';
import Button from '../components/Button';
import SignUpForm from '../components/SignUpForm';
import IndividualsSVG from '../images/individuals.svg';
import CompaniesSVG from '../images/companies.svg';
import CommunitiesSVG from '../images/communities.svg';
import Img from 'gatsby-image';

const HeroSection = styled.section`
	padding: 5rem 0 2rem 0;
	// background-color: ${cwTheme.primaryBlue};
	color: ${cwTheme.white};
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 8rem 0 6rem;
	}

	div {
		max-width: 1020px;
	}
	text-align: center;
	background-image: url(${(props) => props.image.src});
	background-position: center;
	background-size: cover;
`;

const Headline = styled.h1`
	display: block;
	font-size: 2em;
	font-family: ${cwTheme.serif};
	font-weight: 800;
	max-width: 400px;
	margin: 0 auto;
`;

const SubHeadline = styled.p`
	max-width: 500px;
	font-weight: 400;

	margin: .5em auto 1em auto;
`;

const ClientSection = styled.section`
	padding-bottom: 2rem;
	text-align: center;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding-bottom: 5rem;
	}
`;

const ClientLogosWrapper = styled.div`
	margin: .618rem auto;

	img {
		margin: 1rem;
		max-width: 150px;
		width: 100%;
	}
`;

const ClientContainer = styled.div`
	padding: 0 1rem;
	max-width: 60rem;
	margin: 0 auto;
	box-sizing: content-box;
`;

const BigBlockContainer = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	max-width: 400px;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 1rem;

	@media (min-width: ${cwTheme.mediumSmallBreakPoint}) {
		flex-direction: row;
		max-width: 800px;
		padding: 0rem;
	}
`;

const BigMarketingQuoteContainer = styled.div`
	flex-direction: column;
	display: flex;
	align-items: center;
	max-width: 800px;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;

	@media (min-width: ${cwTheme.mediumSmallBreakPoint}) {
		flex-direction: row;
	}
`;

const BigBlockImageContainer = styled.div`
	max-width: 400px;
	width: 100%;
`;

const BigBlockCaptionWrapper = styled.div`
	box-sizing: content-box;
	max-width: 400px;
	width: 100%;
	h4 {
		margin-top: 0;
	}
	margin-top: .5rem;

	@media (min-width: ${cwTheme.mediumSmallBreakPoint}) {
		margin-top: 0;
		padding: 1rem;
		margin-left: 2rem;
	}
`;

const BigBlockSection = styled.section`
	padding: 2rem 0;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 5rem 0;
	}
`;

const TestimonialSection = styled.section`
	padding: 2rem 0;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 5rem 0;
	}
`;
const TestimonialContainer = styled.div`
	text-align: center;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem 3rem 1rem;

	h4 {
		line-height: 1.4;
		display: block;
		max-width: 900px;
		margin: 1rem auto;
		font-family: 'Merriweather', serif;
		font-weight: 300;
	}

	h5 {
		font-style: italic;
		font-weight: 500;
		color: rgba(61, 61, 61, .5);
	}
`;

const ThreeBlockSection = styled.section`
	padding: 3rem 0 4rem 0;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 6rem 0;
	}
`;

const ThreeBlockContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 80rem;
	width: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0 1rem;

	a {
		max-width: 550px;
		margin: 0 auto;
	}
`;

const ThreeBlockWrapper = styled.div`
	flex-direction: column;
	display: flex;
	align-self: center;

	@media (min-width: ${cwTheme.mediumSmallBreakPoint}) {
		text-align: left;
		justify-content: space-around;
		flex-direction: row;
		align-self: stretch;
	}
`;

const ThreeBlock = styled.div`
	flex: 1;
	max-width: 350px;
	h5 {
		margin-top: 1rem;
	}
	p {
		font-weight: 400;
	}

	@media (min-width: ${cwTheme.mediumSmallBreakPoint}) {
		padding: 1rem;
		margin: .5rem;
	}
`;

const TextLink = styled(Link)`
	color: ${cwTheme.secondaryBlue};
	font-weight: 600;
	text-decoration: none;
	border-bottom: 2px solid ${cwTheme.white};
	margin-bottom: 4rem !important;
	&:hover {
		color: ${cwTheme.secondaryHover};
		// border-bottom: 2px solid  ${cwTheme.secondaryHover};
	}
	display: block;

	@media (min-width: ${cwTheme.mediumSmallBreakPoint}) {
		margin-bottom: 0 !important;
	}
`;

const BigBlockHeadLine = styled.h4`
	text-align: center;
	font-family: ${cwTheme.serif};
`;

const ThreeBlockImageContainer = styled.div`
	max-width: 400px;
	width: 100%;
	box-sizing: content-box;
`;

const IndexPage = ({ data }) => (
	<ThemeProvider theme={{ mode: 'light' }}>
		<Layout>
			<SEO title="Home" />
			<HeroSection image={data.contentfulHomePage.heroImage.fluid}>
				<Container>
					<Headline>{data.contentfulHomePage.heroHeadline}</Headline>
					<SubHeadline>{data.contentfulHomePage.heroSubheadline}</SubHeadline>
					<Button
						link={data.contentfulHomePage.heroLink}
						color={cwTheme.secondaryBlue}
						hoverColor={cwTheme.secondaryHover}
					>
						{data.contentfulHomePage.heroButtonText}
					</Button>
				</Container>
			</HeroSection>

			{/* <BigBlockSection>
				<BigMarketingQuoteContainer>
					<BigBlockHeadLine>{data.contentfulHomePage.singleBlockHeadline}</BigBlockHeadLine>
				</BigMarketingQuoteContainer>
			</BigBlockSection> */}

			<ThreeBlockSection>
				<ThreeBlockContainer>
					<ThreeBlockWrapper>
						<ThreeBlock>
							<ThreeBlockImageContainer>
								<Img
									backgroundColor={cwTheme.lightBlue}
									fluid={data.contentfulHomePage.threeBlockImageOne.fluid}
								/>
							</ThreeBlockImageContainer>
							<h5>{data.contentfulHomePage.threeBlockOneTitle}</h5>
							<p>{data.contentfulHomePage.threeBlockOneText.threeBlockOneText}</p>
							<TextLink to={data.contentfulHomePage.threeBlockOneLink}>
								{data.contentfulHomePage.threeBlockOneLinkText}
							</TextLink>
						</ThreeBlock>
						<ThreeBlock>
							<ThreeBlockImageContainer>
								<Img
									backgroundColor={cwTheme.lightBlue}
									fluid={data.contentfulHomePage.threeBlockImageTwo.fluid}
								/>
							</ThreeBlockImageContainer>
							<h5>{data.contentfulHomePage.threeBlockTwoTitle}</h5>
							<p>{data.contentfulHomePage.threeBlockTwoText.threeBlockTwoText}</p>
							<TextLink to={data.contentfulHomePage.threeBlockTwoLink}>
								{data.contentfulHomePage.threeBlockOneLinkText}
							</TextLink>
						</ThreeBlock>
						<ThreeBlock>
							<ThreeBlockImageContainer>
								<Img
									backgroundColor={cwTheme.lightBlue}
									fluid={data.contentfulHomePage.threeBlockImageThree.fluid}
								/>
							</ThreeBlockImageContainer>
							<h5>{data.contentfulHomePage.threeBlockThreeTitle}</h5>
							<p>{data.contentfulHomePage.threeBlockThreeText.threeBlockThreeText}</p>
							<TextLink to={data.contentfulHomePage.threeBlockThreeLink}>
								{data.contentfulHomePage.threeBlockOneLinkText}
							</TextLink>
						</ThreeBlock>
					</ThreeBlockWrapper>
				</ThreeBlockContainer>
			</ThreeBlockSection>

			<BigBlockSection>
				<BigBlockContainer>
					<BigBlockImageContainer>
						<Img
							backgroundColor={cwTheme.lightBlue}
							sizes={data.contentfulHomePage.singleBlockImage.fluid}
						/>
					</BigBlockImageContainer>
					<BigBlockCaptionWrapper>
						<h4>{data.contentfulHomePage.singleImageBlockHeader}</h4>
						<p>{data.contentfulHomePage.singleImageBlockCaption.singleImageBlockCaption}</p>
						<Button
							link={data.contentfulHomePage.singleImageBlockLink}
							color={cwTheme.primaryBlue}
							hoverColor={cwTheme.primaryHover}
						>
							{data.contentfulHomePage.singleImageBlockLinkText}
						</Button>
					</BigBlockCaptionWrapper>
				</BigBlockContainer>
			</BigBlockSection>

			<TestimonialSection>
				<TestimonialContainer>
					<h4>"{data.contentfulHomePage.testimonial.testimonial}"</h4>
					<h5>{data.contentfulHomePage.testimonialAuthor}</h5>
				</TestimonialContainer>
			</TestimonialSection>

			<ClientSection>
				<ClientContainer>
					<ClientLogosWrapper>
						{data.contentfulHomePage.clientLogos.map(({ fixed: logo }) => <img src={logo.src} />)}
					</ClientLogosWrapper>
				</ClientContainer>
			</ClientSection>

			<SignUpForm />
		</Layout>
	</ThemeProvider>
);

export const query = graphql`
	query {
		contentfulHomePage {
			id
			heroHeadline
			heroImage {
				fluid(maxWidth: 2400, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
			heroLink
			heroSubheadline
			heroButtonText
			clientLogosText
			singleBlockHeadline
			singleImageBlockHeader
			singleImageBlockCaption {
				singleImageBlockCaption
			}
			singleImageBlockLink
			singleImageBlockLinkText
			testimonial {
				testimonial
			}
			testimonialAuthor
			threeBlockOneTitle
			threeBlockTwoTitle
			threeBlockThreeTitle
			threeBlockOneText {
				threeBlockOneText
			}
			threeBlockTwoText {
				threeBlockTwoText
			}
			threeBlockThreeText {
				threeBlockThreeText
			}
			signUpHeader
			clientLogos {
				fixed {
					src
				}
			}
			singleBlockImage {
				fluid(maxWidth: 800, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
			threeBlockImageOne {
				fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
			threeBlockImageTwo {
				fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
			threeBlockImageThree {
				fluid(maxWidth: 800, maxHeight: 800, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
			threeBlockOneLink
			threeBlockTwoLink
			threeBlockThreeLink
			threeBlockOneLinkText
			threeBlockTwoLinkText
			threeBlockThreeLinkText
		}
	}
`;

export default IndexPage;
